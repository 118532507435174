import React from "react";
import Layout from '../../components/Layout'
import Navbar from '../../components/Navbar'

export default () => (
  <Layout>
    <div className="contactpage">
      <Navbar />
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <h1>Merci pour <br/>votre message !</h1>
              <p>Nous vous contacterons très rapidement.</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);